import React from 'react';
import PrivacyPolicyComponent from '../components/privacy-policy/privacy-policy.component';
import Layout from '../components/shared/layout';
import CookiePolicyComponent from '../components/terms-and-conditions/cookie-policy.component';
import { WebsiteTermsofUseComponent } from '../components/terms-and-conditions/website-terms-of-use.component';



const TosPage = () => {
  return (
    <div className="tnc-page">
    <Layout header={true} isBackBtn={true} footer={true}>
     <WebsiteTermsofUseComponent/>
     <CookiePolicyComponent/>
     <PrivacyPolicyComponent/>
    </Layout>
  </div>
  )
}

export default TosPage;